@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Smooch+Sans:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxanium:wght@200..800&family=Smooch+Sans:wght@100..900&display=swap");



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  background-color: #fafaf9;
  background-image: url("./components/img/bg.png");
  background-attachment: fixed;
  background-size: cover;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

hr {
  margin: 0 auto;
  width: 90%;
  color: #ff4655;
  border: 5px solid #ff4655;
}

img {
  overflow-x: hidden;
}
.loading-container {
  background-color: black;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
} /* NAVBAR */

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 5rem;
  background-color: #0f1923  ;
}

/* HAMBURGER STYLE */

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  transition: all ease-in-out 0.3s;
  background-color: #ff4655;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 610px;
  font-family: "oxanium";
}

.nav-link {
  font-size: 1.6rem;
  font-weight: 500;
  color: #f64755;
  transition: all ease-in-out 0.2s;
}

.nav-link:hover {
  letter-spacing: 2px;
}

.nav-logo img {
  width: 11rem;

  transition: all ease-in-out 0.2s;
}

.nav-logo img:hover {
  transform: scale(1.2);
}
.no-space + .no-space {
  margin-left: -46px;
}
/* HEADER */
header {
  height: 80vh;
  width: 100%;
  overflow-x: hidden !important;
  margin: 0 0 70px 0;
}
.backgroundwebp {
  width: 100%;
  height: 100%;
  z-index: -2;

  background-color: #0f1923;
}

#myVideo {
  z-index: -2;
}

.head {
  position: absolute;
  display: flex;

  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 24%;
}
.head h1 {
  font-family: "smooch sans";
  font-size: 142px;
  color: #ece8e1;
}
.head p {
  margin-top: 14px;
  font-size: 19px;
  font-family: "oxanium";
  color: #ffffff;
}

.renmu-logo {
  transition: all 0.1s;
  z-index: 5;
}

.floating {
  width: 200px;
  height: auto;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
.eventbtn {
 
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  color: var(--button-text-color);
  cursor: pointer;
  /* Clean style */

  --button-text-color: var(--background-color);
  --button-text-color-hover: 0f1923;
  --border-color: #7d8082;
  --button-background-color: #ff4655;
  --highlight-color: #ece8e1;
  --button-inner-border-color: transparent;
  --button-bits-color: var(--background-color);
  --button-bits-color-hover: var(--button-background-color);

  position: relative;
  padding: 8px;
  top: 40px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.15s ease;
  
  font-family: "oxanium";
}
.eventbtn.second:hover {
  
  color: rgb(0, 0, 0); 
}
.eventbtn::before,
.eventbtn::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  height: calc(50% - 5px);
  border: 1px solid var(--border-color);
  transition: all 0.15s ease;

}
:root {
  --background-color: #ffffff;
}
.eventbtn::before {
  top: 0;
  border-bottom-width: 0;
  
}

.eventbtn::after {
  bottom: 0;
  border-top-width: 0;
}

.btn:active,
.eventbtn:focus {
  outline: none;
}

.eventbtn:active::before,
.eventbtn:active::after {
  right: 3px;
  left: 3px;
}

.eventbtn:active::before {
  top: 3px;
}
.eventbtn:active::after {
  bottom: 3px;
}

.btn__inner {
  position: relative;
  display: block;
  padding: 16px 30px;
  background-color: var(--button-background-color);
  overflow: hidden;
  box-shadow: inset 0px 0px 0px 1px var(--button-inner-border-color);
}

.btn__inner::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  background-color: var(--button-bits-color);
}

.btn__inner::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 4px;
  height: 4px;
  background-color: var(--button-bits-color);
  transition: all 0.2s ease;
}

.btn__slide {
  display: block;
  position: absolute;
  top: 0;
  bottom: -1px;
  left: -8px;
  width: 0;
  background-color: var(--highlight-color);
  transform: skew(-15deg);
  transition: all 0.2s ease;
}

.btn__content {
  position: relative;
}

.eventbtn:hover {
  color: var(--button-text-color-hover);
}

.eventbtn:hover .btn__slide {
  width: calc(100% + 15px);
}

.eventbtn:hover .btn__inner::after {
  background-color: var(--button-bits-color-hover);
}

.btndiv {
  display: flex;
  gap: 30px;
}
/* OTHER AGENTS */
.all-events {
  height: fit-content;
  width: 100%;

  position: relative;
  margin-bottom: 70px;
}

.all-events h1 {
  font-family: "smooch sans";
  font-weight: 800;
  font-size: 10rem;
  margin-bottom: 100px;
}

.all-events h1 span {
  font-weight: 300;
}

.main {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  justify-items: center;
}

.event-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  background-size: contain;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transition: transform 0.3s, filter 0.3s;
  position: relative;
  height: 400px;
  width: 220px;
  overflow: hidden;
  border: 2px solid #333;
  filter: grayscale(0.8);
  background-color: #0f1923;
  cursor: pointer;
}

.event-card-content {
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.event-card h2,
.event-card p,
.event-card .button {
  margin: 10px 0;
}

.event-card h2 {
  color: #ff4655;
  font-family: "smooch sans";
  font-size: 3rem;
}

.event-card p {
  color: #fff;
  font-family: "oxanium";
  text-align: center;
  font-size: 1.2rem;
}

.event-card .button {
  border: none;
  padding: 1rem 3rem;
  transition: all ease-in-out 0.2s;
  font-weight: 700;
  font-size: 2vh;
  color: #fff;
  background: linear-gradient(to right, #ff4655 50%, #ce323f 50%);
  background-size: 200% 100%;
  background-position: right;
  font-family: "oxanium";
}

.event-card .button:hover {
  background-color: #e03535;
  transform: scale(1.05);

  background-position: left;
  box-shadow: 10px 10px 0px 0px #0f1923;
  cursor: pointer;
}

.event-card:hover {
  filter: grayscale(0);
  transform: scale(1.1);
  border: 2px solid #ff4655;
}

.highlight {
  transform: scale(1.1);
  filter: grayscale(0);
  border: 2px solid #ff4655;
}
.highlight:hover {
  transform: scale(1.2);
}

.main .event-card:nth-child(1) {
  left: 35px;
  bottom: 25px;
  z-index: 1;
  transform: translateX() scale(0.9);
  background-image: url(" https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/bltf11234f4775729b7/5ebf2c275e73766852c8d5d4/V_AGENTS_587x900_ALL_Sova_2.png");
}

.main .event-card:nth-child(2) {
  left: 20px;
  bottom: 15px;
  z-index: 2;
  transform: translateX() scale(0.95);
  background-image: url("https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/bltc825c6589eda7717/5eb7cdc6ee88132a6f6cfc25/V_AGENTS_587x900_Viper.png");
}

.main .event-card:nth-child(3) {
  left: 5px;
  z-index: 3;
  transform: translateX() scale(1);
  background-image: url("https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/blt8a627ec10b57f4f2/5eb7cdc16509f3370a5a93b7/V_AGENTS_587x900_sage.png");
}

.main .event-card:nth-child(4) {
  left: 0;
  top: 25px;
  z-index: 4;
  background-image: url("https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/bltceaa6cf20d328bd5/5eb7cdc1b1f2e27c950d2aaa/V_AGENTS_587x900_Jett.png");
}

.main .event-card:nth-child(5) {
  right: 5px;
  z-index: 3;
  transform: translateX() scale(1);
  background-image: url("https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/bltd4080f8efb365751/5ff5660bb47cdf7fc7d6c3dc/V_AGENTS_587x900_yoru.png");
}

.main .event-card:nth-child(6) {
  right: 20px;
  bottom: 15px;
  z-index: 2;
  transform: translateX() scale(0.95);
  background-image: url(" https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/blt302fcb2b9628c376/5f7fa6ff8db9ea0f149ece0a/V_AGENTS_587x900_ALL_Skye.png");
}

.main .event-card:nth-child(7) {
  right: 35px;
  bottom: 25px;
  z-index: 1;
  transform: translateX() scale(0.9);
  background-image: url("https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/blt53405c26141beff8/5f21fda671ec397ef9bf0894/V_AGENTS_587x900_KillJoy_.png");
}

/* PLAY NOW */
.more-events {
  justify-content: center;
  height: 20vh;
  text-align: center;
  margin-top: 120px;
}

.more-events button {
  border: none;
  padding: 16px 32px;
  transition: all ease-in-out 0.2s;
  font-weight: 700;
  font-size: 3vh;
  color: #fff;
  background: linear-gradient(to right, #ff4655 50%, #ce323f 50%);
  background-size: 200% 100%;
  background-position: right;
  box-shadow: 5px 5px 0px 0px #0f1923;
  font-family: "oxanium";
}

.more-events button:hover {
  letter-spacing: 5px;
  background-position: left;
  box-shadow: 10px 10px 0px 0px #0f1923;
  cursor: pointer;
}

.more-events button:active {
  letter-spacing: 5px;
  background-position: left;
  box-shadow: 10px 10px 0px 0px #0f1923;
  cursor: pointer;
}

/* FOOTER */
footer {
  display: flex;
  
  background-color: #0f1923;
  width: 100%;
  height: 40vh;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  font-family: "oxanium";
  color: #fff;
}
footer div {
  display: flex;
  align-items: center;
}
footer .nav-logo {
  padding: 0 20px 0 20px;
}

footer p {
  font-size: 2rem;
  letter-spacing: 1px;
}

footer .p2 {
  letter-spacing: 0;
  font-weight: 300;
  font-size: 1.5rem;
}
.Social-icon{
  display: grid;
  gap: 25px;

}
.Social-icon ul {
  display: flex;
  width: 230px;
  justify-content: space-around;
}

.Social-icon img {
  width: 32px;
  transition: all ease-in-out 0.2s;
}
.Social-icon img:hover {
  transform: scale(1.2);
}
footer .footer-logos{
  display: grid;
 
}
footer .contact{
  display: grid;
  gap: 20px;
}
footer .contact ul{
   text-align: justify;
   font-size: 14px;
}
.logo-f{
  display: flex;
  justify-content: center;
}

@media all and (max-width: 1400px) {
  .swiper_container {
    height: 52rem;
    padding: 2rem 0;
    position: relative;
  }
  .swiper-slide-active .event-card {
    transform: scale(1.1);
    filter: grayscale(0);
    border: 2px solid #ff4655;
  }
  .event-card:hover {
    filter: none;
    transform: none;
    border: none;
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 0;
    height: 100%;
    position: relative;
    transition-property: transform;
    display: block;
  }
  .swiper_container {
    height: 47rem;
  }
  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }
  .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }

  .swiper-slide img {
    width: 37rem;
    height: 42rem;
    border-radius: 2rem;
    object-fit: cover;
  }

  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    display: none;
  }

  .slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper-wrapper {
    margin-top: 30px;
  }
  .main {
    display: flex;
    justify-content: center;
  }
}
@media all and (max-width: 521px) {
  .navbar {
    display: flex;
    justify-content: end;
  }
  .nav-menu {
    position: fixed;
    left: -100%;
    top: 0;
    flex-direction: column;
    justify-content: center;
    background-color: #0f1923;
    width: 260px;
    height: 100vh;
    padding: 50px 0;
    text-align: center;
    transition: 0.3s;
    z-index: 10;
  }

  .nav-link {
    color: #ece8e1;

    font-weight: 500;
    font-size: 3rem;
  }

  .nav-menu.active {
    left: 0;
    z-index: 10;
  }

  .nav-item {
    margin: 2.5rem 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    z-index: 100;
  }

  .hamburger.active {
    right: 8vh;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .bar.active {
    background-color: #e03535;
  }
  .navbar {
    padding: 0;
    display: flex;
    justify-content: space-between;
    padding-right: 25px;
  }

  .head h1 {
    font-size: 80px;
    margin: 0px 10px 0 10px;
  }
  .head p {
    font-size: 15px;
    margin: 0px 10px 0 10px;
  }
  header {
    height: 90vh;
    overflow: hidden;
    margin: 0 0 50px 0;
  }

  .head {
    top: 18%;
    gap: 10px;
  }
  .btndiv {
    gap: 15px;
  }
  .more-events {
    margin-top: 0;
    height: 15vh;
  }
  .more-events button {
    font-size: 15px;
  }
  .more-events button:hover {
    letter-spacing: 1px;
  }
  .all-events {
    margin-bottom: 0;
  }
  .all-events h1 {
    font-size: 5rem;
    margin-bottom: 10px;
  }
  .swiper-wrapper {
    margin-top: 60px;
  }
}
@media (min-width: 522px) and (max-width: 650px) {
  .more-events button {
    font-size: 15px;
  }
  .more-events button:hover {
    letter-spacing: 1px;
  }
  .navbar {
    display: flex;
    justify-content: end;
  }
  .nav-menu {
    position: fixed;
    left: 0;
    top: -100vh;
    flex-direction: column;
    justify-content: center;
    background-color: #ff4655;
    width: 100%;
    height: 100vh;
    padding: 50px 0;
    text-align: center;
    transition: 0.3s;
    z-index: 10;
  }

  .nav-link {
    color: #0f1923;
    font-weight: 500;
    font-size: 3rem;
  }

  .nav-menu.active {
    top: 0;
    z-index: 10;
  }

  .nav-item {
    margin: 2.5rem 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    z-index: 100;
  }

  .hamburger.active {
    position: fixed;
    right: 8vh;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .bar.active {
    background-color: #0f1923;
  }
  .navbar {
    padding: 14px;
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
  }

  .head h1 {
    margin-top: 20px;
    font-size: 70px;
  }
  .head p {
    font-size: 15px;
  }
  header {
    height: 600px;
  }

  .more-events {
    margin-top: 0;
  }
  .all-events {
    margin-bottom: 20px;
  }
  .all-events h1 {
    font-size: 7rem;
    margin-bottom: 10px;
  }
  .swiper-wrapper {
    margin-top: 60px;
  }
}

@media (min-width: 650px) and (max-width: 767px) {
  header {
    height: 600px;
  }
  .head h1 {
    font-size: 80px;
  }
  .navbar {
    padding: 0;
  }

  .more-events {
    margin-top: 0;
  }
  .all-events {
    margin-bottom: 0;
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  header {
    height: 600px;
    margin: 0 0 70px 0;
    overflow: hidden;
  }

  .swiper-wrapper {
    margin-top: 60px;
  }
  .navbar {
    padding: 0;
  }
  .head h1 {
    font-size: 100px;
  }
  .more-events {
    margin-top: 0;
  }
  .all-events {
    margin-bottom: 10px;
  }
}

/* Desktops and Larger Screens */
@media (min-width: 1025px) and (max-width: 1150px) {
  header {
    height: 800px;
    margin: 0 0 100px 0;
    overflow: hidden;
  }
  .head h1 {
    font-size: 120px;
  }
}

/* CountdownTimer.css */

.countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #ffffff; /* Text color (white) */
  background-color: #1f2833; /* tech theme background color */
  padding: 20px 35px;

  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  animation: pulse 1s infinite alternate; /* Cool pulsating effect */
}

/* Timer display */
.countdown-timer {
  display: flex;
  align-items: center;
}

/* Timer parts (days, hours, minutes, seconds) */
.timer-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

/* Timer values */
.timer-value {
  font-size: 24px;
  font-weight: bold;
  color: #ff4655; /* tech red color for numbers */
}

/* Timer labels (Days, Hours, Minutes, Seconds) */
.timer-label {
  font-size: 12px;
}

/* Separator between timer parts */
.timer-separator {
  font-size: 24px;
  margin: 0 5px;
}

/* Expired notice (when time is up) */
.expired-notice {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #ff4655; /* tech red color */
}

/* Cool pulsating effect animation */
@keyframes pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}

.event-detail {
  position: absolute;
  border-radius: 20px;
  top: 10%;
  gap: 10px;
  margin: 30px;
  margin-top: 50px;
  height: 75vh;
  display: flex;
  width: 85vw;
}
.event-detail img {
  background-color: rgb(31, 40, 51);
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  object-fit: fit;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  width: 850px;
}
.event-detail .event-info {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  background-color: rgb(246, 71, 85);
  text-align: center;
  width: 100%;
  background-image: url(./components/img/bg.png);
  background-position: top;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.event-code {
  position: absolute;
  border-radius: 20px;
  top: 90%;
  gap: 10px;

  padding: 0 120px 40px 120px;
  margin: 30px;
  height: max-content;
  display: flex;
  width: 85vw;
  background-color: rgb(246, 71, 85);
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url(./components/img/bg.png);
  background-position: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.event-code p {
  font-family: "oxanium";
  font-size: 18px;
  margin: 0px 20px;
  text-align: justify;
  font-weight: bold;
  color: #1f2833;
}
.event-code h1 {
  font-family: "smooch sans";
  font-size: 72px;
  color: #1f2833;

  margin-top: 20px;
}
.event-code li{
  padding-bottom: 10px;
}

.event-code ul{
  margin-right: 28px;
  margin-left: 28px;
  font-size: 15px;
  text-align: justify;
}
.event-info h1 {
  font-family: "smooch sans";
  font-size: 72px;
  color: #1f2833;

  margin-top: 20px;
}

.event-info p {
  font-family: "oxanium";
  font-size: 18px;
  margin: 38px;
  text-align: justify;
  font-weight: bold;
  color: #1f2833;
}
.event-info ul {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 38px;
  font-family: "oxanium";
  color: #1f2833;
  font-weight: 600;
  font-size: 20px;
}
.details-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin: "0";
}

.details-head .navbar {
  background-color: inherit;
  padding: 40px;
}
.details-head a {
  color: #1f2833;
  font-weight: 600;
}
.event-info .eventbtn {
  top: 20px;
}

/*faq*/

#faq {
  color: #000000;
  padding: 180px;
  padding-top: 0;
  padding-bottom: 100px;
}

.faq__container {
  margin-top: 0;
}

.faq__title {
  margin-bottom: 2rem;
  font-size: 6rem;
  font-family: "smooch sans";
}

.faq__item {
  border-bottom: 1px solid #333;
  padding: 1rem 0;
  font-family: "oxanium";
}

.faq__question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 2rem;
}

.faq__toggle {
  font-size: 3rem;
}

.faq__answer {
  max-height: 0;
  overflow: hidden;
  transition:  max-height ease-in 0.2s;
 
  
}

.faq__answer--active {
  display: block;
  max-height: 100px;
}
#faq p{
  font-size: 2rem;
}

.partners h1{
  font-family: "smooch sans";
  font-size: 9rem;
  color: #000000;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}
.partners .logo-part{
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  margin-bottom: 80px;
}
.partners img {
  width: 180px;
  align-self: center;
  justify-self: center;
  transition: all ease-in-out 0.2s;
}


.partners img:hover {
  transform: scale(1.2);
}

@media all and (max-width: 750px) {

  .partners h1{
    font-size: 5rem;
   
  }
  .partners img{
    width: 120px;
  }
  .partners .logo-part{
    grid-template-columns: repeat(2, 1fr);
    margin: 10px;
    gap: 30px;
    margin-bottom: 60px;
  }


  .event-detail {
    display: block;
    width: auto;
    height: auto;
    position: relative;
    border: 20px;
    margin: 0;
    gap: 0;
  }
  .event-detail img {
    width: 90%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 20px;
  }
 
  .event-info h1 {
    margin-top: 0;
    font-size: 44px;
  }
  .event-info p {
    font-size: 13px;
    margin: 18px;
    font-weight: bolder;
  }
  .event-info ul {
    font-size: 13px;
    margin-left: 18px;
  }
  .event-info .eventbtn {
    font-size: inherit;

    top: 15px;
  }
  .details-head {
    height: 100%;
    margin: 0;
  }
  .event-detail .event-info {
    height: 60vh;
    border-radius: 0;
    margin-top: -3px;
    box-shadow: none
  ;
  }
  .event-code {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    box-shadow: none;
    top: 113vh;
    margin: 0;
    width: inherit;
    padding: 0;
    padding-bottom: 20px;
  }
  .event-code p{
    font-size: 13px;
  }
  .event-code p{
    padding: 0;
  }
  .event-code ul{
    font-size: 12px;
  }
  .event-code h1 {
    margin: 10px;
    margin-top: 0;
    font-size: 44px;
  }
  .details-head .navbar {
    padding: 0;
    position: absolute;
  }
  #faq {
    padding: 15px;
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  .faq__title {
    font-size: 3.4rem;
  }
  .faq__question {
    font-size: 1.2rem;
  }
  .faq__toggle {
    font-size: 2.3rem;
  }
  .countdown-container {
    padding: 20px;
  }
  #faq p{
    font-size: 1.5rem;
  }
}

.custom-class {
  position: absolute;
  bottom: 2.5rem; 
  right: 49.5%; 
  transform: translateX(50%) scale(1.25);
  cursor: pointer;
  animation: stb 1s ease-in-out infinite;
  height: 35px;
  top: 90vh;

}
/* Example animation */
@keyframes stb {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.custom-class .space{
  height: 1.9rem;
  width: 1.9rem;
  margin-bottom:-4px ;
}
.spacex{
  margin-top: -5px;

}
.spacey{
  margin-top: -10px;
}




.about h1{
  font-family: "smooch sans";
  font-size: 10rem;
  color: #000000;

}

.about p{
  font-size: 20px;
  font-family: 'oxanium';


  font-weight: 600;
  text-align: center;
}
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw; 
  margin: 0 auto;
  padding: 60px; 
  padding-top: 0;
  font-size: 18px;
  line-height: 1.6; 
}
/*why partner with us?*/


.section-hero {
  display: flex;
  align-items: center;
  gap: 120px;
  background:#0f1923;
  padding:160px 120px 160px;
  overflow:hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.section-hero img {
  width: 47%;
  bottom: 10px;
  right: 45px;
  height: auto;
  position: absolute;
}
.section-hero .text{
  width: 50%;
  text-align: justify;
}


.section-hero h1 {
  font-size: 55px;
  font-family: "smooch sans";
  color: #ffffff;
  margin-bottom: 20px;
}

.section-hero .span {

  color: #ff4655;
}
.section-hero.eventbtn.second:hover {
  
  color: rgb(0, 0, 0); 
}
.section-hero .text p, .section-hero .text ul {
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  font-family: "oxanium";
  text-align: justify;
}
.section-hero .text p{
  margin-bottom: 10px;
  line-height: 27px;
  padding-right: 140px;
}

.section-hero .text ul {
  padding-left: 20px;

}

.section-hero .text ul li {
  margin-bottom: 10px;
}
.section-hero .small-img{
  display: none;
}
.section-sponsorship {
  padding: 20px;
}

.text {
  text-align: center;
  font-size: 3rem;
  font-family: 'smooch sans';
}

.perks-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.perk {

  border-radius: 8px;
 
  padding: 20px;
  width: 100%;
  
}

.titleSection {
  font-size: 5rem;
  text-align: center;


}

.investment {
  display: flex;
 
  margin-bottom: 10px;
  justify-content: center;
  font-size: 2rem;
  margin-bottom: 50px;
  font-weight: 700;
}

.investmentIcon {
  margin-right: 5px;
}

.benefits-list {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-template-rows: repeat(3, 1fr);
   gap:30px 0px; 
   margin-bottom: 80px;
   margin-top: 0;
  
}


.benefit-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;

  padding: 20px;
  text-align: center;
 
}
.benefit-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  font-family: 'oxanium';
 
 
}



.benefit-div p{
  font-size: 1.5rem;
  text-align: center;
  padding: 0 110px;

}
.benefit-div img{
  width: 130px;
  height: 130px;
}
.benefit-div.Title:nth-child(10){
  grid-column: 2;
}


.benefit-div.Silver:nth-child(4){
  grid-column: 2;
}
.benefits-list.Silver{
  grid-template-rows: none;
}


.Count{
  display: grid;
  padding:40px;
  gap: 30px;
  background-color: #F8F8F8;
  
  
}
.Count h1{
  justify-self: center;
  font-size: 40px;
  font-family: 'smooch sans';
}
.stats-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 20px;
  
}

.stat-item {
  text-align: center;
}

.stat-item h2 {
  color: #ff4b58;
  font-size: 5em;
  margin: 0;
}

.stat-item p {
  color:#ce323f;
  font-size: 2em;
  font-family: 'oxanium';
  margin: 0;
}

.dev{
 background-image: url("./components//img/background.svg");
  background-size: cover;
  height: 80vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
 align-items: center;
 margin-bottom: 50px;
}
.dev img{
 width: 500px;
 padding-top: 40px;
  
}
.head-dev{
  display: flex;
  flex-direction: column;
  align-items: center;

}
.head-dev h1{
  font-family: "smooch sans";
  font-size: 100px;
  color: #ece8e1;
}
.head-dev p {
  margin-top: 14px;
  font-size: 19px;
  font-family: "oxanium";
  color: #ffffff;
  width: 604px;
}
.head-dev button{
  width: 230px;
  font-size: 18px;
  
}
.head-dev .btn__inner{
  background-color:#1e2e3d;
}
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
 z-index: 100;
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 1000px;
  height: 60vh;
  width: 100%;
  text-align: center;
  display: flex;
  gap: 45px;
 
}
.card h2{
  font-family: "smooch sans";
  font-size: 40px;
}
.card p{
  font-family: "oxanium";
  font-size: 15px;
}
/* Close Button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

/* Cards */
.card {
  background-color: #f9f9f9;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  align-self: center;
}

@media (max-width: 768px) {
    .dev img{
      display: none;
    }
    .modal-content{
      display: flex;
      padding: 0;
      gap: 10px;
      width: 100vw;
    }
   .card h2{
    font-size: 3rem;

   }
   .card p{
    font-size: 1.2rem;
    margin-bottom: 10px;
   }
   .card button{
    font-size: 1rem;
    padding: 6px;
   }
   .card{
    height: 40vh;
    text-align: center;
    padding: 22px;
   }
   
   .card .btn__inner{
    padding: 13px 19px;
   }

  .head-dev h1{
    font-size: 70px;
  }
  .head-dev p{
    padding: 13px;
  
    width: auto;
    align-self: baseline;
  }
    .head-dev button{
      width: auto;
    }


  .about {
      font-size: 16px;
      
  }
  .about h1 {
    font-size: 6rem;
  }
  .custom-class{

    right: 49%;
  }
  .section-hero{
    display: grid;
    justify-content: center;
    height: 80vh;
    padding: 15px;
    
    align-items: flex-start;
  }
  .section-hero .text span{
    justify-self: center;
  }

  .section-hero .text{
    width: auto;
  }
  .section-hero img{
    display: none;
  }
  .section-hero h1 {
    font-size: 5rem;
    font-family: "smooch sans";
    color: #ffffff;
    margin-bottom: 30px;
    display: grid;
    justify-content: center;

  }
  .section-hero .text p{
    font-size: 13px;
    line-height: 1.4;
    color: #ffffff;
    font-family: "oxanium";
    text-align: justify;
    padding-right: 0;
    padding: 20px;
  }

  .section-hero .btndiv{
    
     justify-content: center;
     margin-top: 15px;

  }
  .section-hero button{
      font-size: 1rem;
  }
  .section-hero .btn__inner{
    padding: 12px 20px;
  }
  .section-hero .small-img{
    display: flex;
    width: 27%;
  
  }
  .section-hero .small-div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-bottom: 10px

  }
  .section-hero .text ul {
    padding-left: 0;
  
  }



footer .nav-logo img{
  width: 9rem;
}


footer {
  display: flex;
  flex-direction: column;
  background-color: #0f1923;
  width: 100%;
  height: 39vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "oxanium";
  color: #fff;
}
footer div {
  display: flex;
  align-items: center;
}
footer .nav-logo {
  padding: 0 20px 0 20px;
}

footer p {
  font-size: 16px;
  letter-spacing: 1px;
}

footer .p2 {
  letter-spacing: 0;
  font-weight: 300;
  font-size: 13px;
}

.Social-icon ul {
  display: flex;
  width: 300px;
  justify-content: space-around;
}

.Social-icon img {
  width: 24px;
  transition: all ease-in-out 0.2s;
}
.Social-icon img:hover {
  transform: scale(1.2);
}
footer .contact{
  display: none;
}
footer .Social-icon h1{
  display: none;
}
.Count{
  display: block;
  text-align: center;
  background: ECE8E1;
}
.Count h1{
  display: flex;
 
  font-size: 2.4rem;
  margin-bottom: 20px;
  
 
}
.stats-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  
}
.stat-item{
  font-size: 0.7rem;
}
.section-sponsorship {
  display: flex;
  flex-direction: column;
  align-items: center;
   color: #ffffff;
  font-family: "oxanium";
  margin: 0;
  
}
.benefits-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  
  margin: 30px;
  margin-top: 0;
  gap: 40px;
 
}
.benefits-list p{
  padding: 0;
  font-size: 1.3rem;
}
.section-sponsorship h1 {
  font-size: 3.7rem;
  text-align: center;
  font-family: "smooch sans";
  display: flex;
  justify-content: space-around;

  margin-top: 20px;
  margin-bottom: 40px;
  color: #0f1923;
  

}


.section-sponsorship button{
  font-size: 1rem;
  top: 0;
  margin-left: 0;
 
}
.section-sponsorship .btn__inner{
padding: 12px 20px;
}

.perks-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
}


.perk {
  display: flex;
  flex-direction:column;
  justify-content: space-between;
  padding: 20px;

  width: auto;
  height:fit-content;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
  gap: 20px;
}

.titleSection {
  flex: 1;
  color: #1f2833;
  padding: 0;
  
}

.titleSection .title {
  font-size: 6rem;
  font-family: 'smooch sans';
}

.investment {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 800;
  color:#1f2833;
  margin-bottom: 20px;
  font-family: 'oxanium';
}

.investmentIcon {
  margin-right: 5px;
}
.benefit-div img {
  width: 100px;
  height: 100px;
}


.list {
 
padding: 0;
  font-size: 14px;
  color: #1f2833;
  font-weight: 600;
}
.list li{
  list-style: unset;
}
.deliverablesSection {
  background-color: #FF5E5E;
  color: white;
  padding: 20px;
  position: relative;
  width: 245px;
  height: 125px;
  top: 20px;
  right: -50px;
  
}

.deliverablesTitle {
  font-size: 1.4rem;
  margin: 0 0 5px 0;
}

.deliverablesList {
  
  padding-left: 10px;
  font-size: 1.2rem;
  position: relative;
  z-index: 33;
}
.deliverablesList li{
  list-style: unset;
}
.gunImage {
  position: absolute;
  right: -3px;
  top: 12%;
  transform: translateY(-50%);
  width: 200px;
}

.section-sponsorship
.btndiv {
  
  justify-content: center;
  color: black;
}
.dev{
  background-color: #0f1923;
  height: 70vh;
  
  display: flex;
}



}

@media (max-width: 480px) {

  .about {
      font-size: 14px;
      padding: 20px;
      padding-top: 0;
      padding-bottom: 40px;
  }
  .about p{
    font-size: 14px;
    text-align: justify;

  }



}
